import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import didInsert from "@ember/render-modifiers/modifiers/did-insert";
import { service } from "@ember/service";
import PluginOutlet from "discourse/components/plugin-outlet";
import { isiPad } from "discourse/lib/utilities";
import observeIntersection from "discourse/modifiers/observe-intersection";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export let topicTitleDecorators = [];
export function addTopicTitleDecorator(decorator1) {
  topicTitleDecorators.push(decorator1);
}
export function resetTopicTitleDecorators() {
  topicTitleDecorators.length = 0;
}
export default class TopicTitle extends Component {
  static #_ = (() => dt7948.g(this.prototype, "header", [service]))();
  #header = (() => (dt7948.i(this, "header"), void 0))();
  applyDecorators(element1) {
    const fancyTitle1 = element1.querySelector(".fancy-title");
    if (fancyTitle1) {
      topicTitleDecorators.forEach(cb1 => cb1(this.args.model, fancyTitle1, "topic-title"));
    }
  }
  static #_2 = (() => dt7948.n(this.prototype, "applyDecorators", [action]))();
  keyDown(e1) {
    if (document.body.classList.contains("modal-open")) {
      return;
    }
    if (e1.key === "Escape") {
      e1.preventDefault();
      this.args.cancelled();
    } else if (e1.key === "Enter" && (e1.ctrlKey || e1.metaKey || isiPad() && e1.altKey)) {
      // Ctrl+Enter or Cmd+Enter
      // iPad physical keyboard does not offer Command or Ctrl detection
      // so use Alt+Enter
      e1.preventDefault();
      this.args.save(undefined, e1);
    }
  }
  static #_3 = (() => dt7948.n(this.prototype, "keyDown", [action]))();
  static #_4 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      {{! template-lint-disable no-invalid-interactive }}
      <div
        {{didInsert this.applyDecorators}}
        {{on "keydown" this.keyDown}}
        {{observeIntersection this.header.titleIntersectionChanged}}
        id="topic-title"
        class="container"
      >
        <div class="title-wrapper">
          {{yield}}
        </div>
  
        <PluginOutlet
          @name="topic-title"
          @connectorTagName="div"
          @outletArgs={{hash model=@model}}
        />
      </div>
    
  */
  {
    "id": "BZV/K0Dd",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[11,0],[24,1,\"topic-title\"],[24,0,\"container\"],[4,[32,0],[[30,0,[\"applyDecorators\"]]],null],[4,[32,1],[\"keydown\",[30,0,[\"keyDown\"]]],null],[4,[32,2],[[30,0,[\"header\",\"titleIntersectionChanged\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"title-wrapper\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[32,3],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"topic-title\",\"div\",[28,[32,4],null,[[\"model\"],[[30,1]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@model\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-title.js",
    "scope": () => [didInsert, on, observeIntersection, PluginOutlet, hash],
    "isStrictMode": true
  }), this))();
}